<template>
  <div class="card card-custom card-transparent">
   
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps" style="justify-content: left;">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
              @click="stepOne()"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">
                  1
                </div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Pengurusan Nasabah
                  </div>
                  <div class="wizard-desc">
                    Tambah Data
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step" @click="stepTwo()">
              <div class="wizard-wrapper">
                <div class="wizard-number">
                  2
                </div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Proses Pengerjaan
                  </div>
                  <div class="wizard-desc">
                    Tambah Proses Pengerjaan
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step" @click="stepThree()">
              <div class="wizard-wrapper">
                <div class="wizard-number">
                  3
                </div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Dokumen
                  </div>
                  <div class="wizard-desc">
                    Tambah Dokumen
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step" @click="stepFour()">
              <div class="wizard-wrapper">
                <div class="wizard-number">
                  4
                </div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Tanggal Akad
                  </div>
                  <div class="wizard-desc">
                    Tambah Tanggal Akad
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <v-app>
          <div class="card card-custom card-shadowless rounded-top-0">
            <div class="card-body p-0">
              <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                <div class="col-xl-12 col-xxl-12">
                  <!--begin: Wizard Form-->
                  <!-- <form class="form mt-0 mt-lg-10" id="kt_form"> -->
                    <v-progress-linear
                      indeterminate
                      color="pink darken-2"
                      v-show="progressForm"
                      style="height:15px;"
                    ><div><span>Proses Data...</span></div></v-progress-linear>
                    <!--begin: Wizard Step 1-->
                  <v-form ref="formUtama" v-model="valid" v-show="loadForm" lazy-validation>
                    <div
                      class="pb-5"
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                    <div class="row">
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Jenis Pengurusan</label>
                          <v-select
                            v-model="formItem.jenis_pengurusan"
                            @change="(event) => showChildJenisPeng(event)"
                            :items="itemJenisPengurusan"
                            label="Jenis Pengurusan"
                            item-value="id"
                            item-text="jenis"
                            clearable
                            solo
                            required
                          ></v-select>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group" v-show="childPengurusan">
                        <label>Notaris Kerjasama</label>
                        <v-select
                          v-model="formItem.id_notaris_kerjasama"
                          :items="itemNotaris"
                          label="Notaris Kerjasama"
                          item-value="id"
                          item-text="nama"
                          clearable
                          solo
                          required
                        ></v-select>
                      </div>
                      </v-col>
                      <v-col
                        cols="4"
                        sm="4"
                        md="4"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <v-radio-group
                          v-model="rPengurusanLain"
                          mandatory
                        >
                          <v-radio
                            @click="doTransaksi()"                          
                            v-model="rTransaksi"
                            label="Transaksi"
                          ></v-radio>
                          <v-radio
                            @click="doKredit()"   
                            label="Kredit"
                            v-model="rKredit"
                          ></v-radio>
                          <v-radio
                            @click="doSertifikat()"   
                            label="Sertifikat"
                            v-model="rSertifikat"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col
                        cols="4"
                        sm="4"
                        md="4"
                        class="pb-0 mb-0"
                        v-show="isTransaksi"
                      >
                      <div class="form-group">
                        <v-select
                          v-model="formItem.transaksi_dtl"
                          @change="(event) => showChildTrans(event)"
                          :items="itemTransaksi"
                          item-value="id"
                          item-text="transaksi"
                          label="Transaksi"
                          clearable
                          solo
                          required
                        ></v-select>
                      </div>
                      </v-col>
                      <v-col
                        cols="4"
                        sm="4"
                        md="4"
                        class="pb-0 mb-0"
                        v-show="isKredit"
                      >
                      <div class="form-group">
                        <v-select
                          v-model="formItem.kredit_dtl"
                          @change="(event) => showChildKredit(event)"
                          :items="itemKredit"
                          label="Kredit"
                          item-value="id"
                          item-text="kredit"
                          clearable
                          solo
                          required
                        ></v-select>
                      </div>
                      </v-col>
                      <v-col
                        cols="4"
                        sm="4"
                        md="4"
                        class="pb-0 mb-0"
                        v-show="isSertifikat"
                      >
                      <div class="form-group">
                        <v-select
                          v-model="formItem.sertifikat_dtl"
                          @change="(event) => showChildSerti(event)"
                          label="Sertifikat"
                          :items="itemSertifikat"
                          item-value="id"
                          item-text="sertifikat"
                          clearable
                          solo
                          required
                        ></v-select>
                      </div>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col
                        cols="4"
                        sm="4"
                        md="4"
                        class="pb-0 mb-0"
                        v-show="childSertifikat"
                      >
                      <div class="form-group">
                        <v-text-field
                          v-model="formItem.sertifikat_lain"
                          id="sertifikat_lain"
                          label="Sertifikat Lainnya"
                          clearable
                          required
                          solo
                        ></v-text-field>
                        <label>Sertifikat Lainnya</label>
                      </div>
                      </v-col>
                      <v-col
                        cols="4"
                        sm="4"
                        md="4"
                        class="pb-0 mb-0"
                        v-show="childTransaksi"
                      >
                      <div class="form-group">
                        <v-text-field
                          v-model="formItem.transaksi_lain"
                          id="transaksi_lain"
                          label="Transaksi Lainnya"
                          clearable
                          required
                          solo
                        ></v-text-field>
                        <label>Transaksi Lainnya</label>
                      </div>
                      </v-col>
                      <v-col
                        cols="4"
                        sm="4"
                        md="4"
                        class="pb-0 mb-0"
                        v-show="childKredit"
                      >
                      <div class="form-group">
                        <v-text-field
                          v-model="formItem.kredit_lain"
                          id="kredit_lain"
                          label="Kredit Lainnya"
                          clearable
                          required
                          solo
                        ></v-text-field>
                        <label>Kredit Lainnya</label>
                      </div>
                      </v-col>
                        <v-col
                        cols="4"
                        sm="4"
                        md="4"
                        class="pb-0 mb-0"
                        v-show="childKredit2"
                      >
                      <div class="form-group">
                        <v-select
                          v-model="formItem.fidusia_dtl"
                          :items="itemFidusia"
                          label="Pilihan Fidusia"
                          item-value="id"
                          item-text="fidusia"
                          clearable
                          solo
                          required
                        ></v-select>
                        <label>Fidusia</label>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Nasabah</label>
                            <!-- v-show="access.creators.is_show"
                            :disabled="!access.creators.is_editable" -->
                          <v-autocomplete
                            :items="cNasabah"
                            v-model="formItem.id_nasabah"
                            :return-object="true"
                            id="id_nasabah"
                            item-text="nama"
                            item-value="id"
                            @change="(event) => sendToText(event)"
                            label="Nasabah"
                            @keypress="onPress"
                            @keyup.delete="onPress"
                            :search-input.sync="search"
                            :no-filter = false
                            :hide-no-data="NoData"
                            :no-data-text="'Data Tidak Ditemukan'"
                            @focus="focusAutocomplete"
                            :rules="idFormBiasa"
                            solo
                            required
                          >
                            <template v-slot:append-item>
                            <div v-intersect="onIntersect">
                                <v-list-item v-if="textLoad">
                                <v-list-item-content>
                                  <v-list-item-title>
                                      {{textLoad}}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </div>
                            </template>
                          </v-autocomplete>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Penjamin</label>
                        <v-select
                          v-model="formItem.id_penjamin"
                          :items="itemPenjamin"
                          label="Penjamin"
                          item-value="id"
                          item-text="nama"
                          clearable
                          solo
                          required
                        ></v-select>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Bank</label>
                        <v-text-field
                          v-model="formItem.bank"
                          :disabled="true"
                          id="bank"
                          label="Bank"
                          clearable
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Cabang</label>
                        <v-text-field
                          v-model="formItem.cabang"
                          :disabled="true"
                          id="cabang"
                          label="Cabang"
                          clearable
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Tanggal Terima Order</label>
                        <v-menu
                          v-model="menu4"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="formItem.tgl_order_baru"
                              label="Tanggal Terima Order"
                              prepend-icon="mdi-calendar"
                              solo
                              required
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="tgl_order_sementara"
                            @input="menu4 = false"
                            id="tgl_order"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Tanggal Berakhir Kepengurusan</label>
                        <v-menu
                          v-model="menu14"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="formItem.tgl_akhir_pengurusan_baru"
                              label="Tanggal Berakhir Masa Kepengurusan"
                              prepend-icon="mdi-calendar"
                              solo
                              required
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="tgl_akhir_pengurusan_sementara"
                            @input="menu14 = false"
                            id="tgl_akhir_pengurusan"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group row align-items-center">
                        <div class="col-md-5 my-auto pb-0"><span>Ingatkan Tgl Berakhir</span></div>
                        <div class="col-md-1 my-auto">
                          <v-checkbox
                            v-model="formItem.reminder"
                            label="Aktifkan"
                            color="red darken-3"
                            hide-details
                          ></v-checkbox>
                        </div>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Deskripsi</label>
                        <v-textarea
                          v-model="formItem.deskripsi"
                          label="Deskripsi"
                          clearable
                          solo
                          ></v-textarea>
                      </div>
                      </v-col>
                    </div>
                    </div>
                  </v-form>
                <!-- </div>
              </div> -->
              <!--end: Wizard Step 1-->
              <!--begin: Wizard Step 3-->
                <div class="pb-5" data-wizard-type="step-content">
                  <v-data-table
                    :headers="headerProses"
                    :items="dProses"
                    :options.sync="optionsPro"
                    :server-items-length="tdProses"
                    :loading="loadingTablePro"
                    loading-text="Proses Data..."
                    class="elevation-1"
                    :footer-props="{
                        'items-per-page-text':'Data Per Halaman',
                        'items-per-page-options': [5, 25, 50, 100]
                    }"
                  >
                  <template v-slot:[`item.number`]="{ index }">
                    <td align="center">{{(optionsPro.page * optionsPro.itemsPerPage) - optionsPro.itemsPerPage + (index + 1)}}</td>
                  </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <v-chip
                        color="#a6808c"
                        dark
                      >
                        {{ item.status_fe }}
                      </v-chip>
                    </template>
                    <template v-slot:top>
                      <v-toolbar flat>
                          <v-toolbar-title><b>Proses Pengerjaan</b></v-toolbar-title>
                          <v-divider
                            class="mx-4"
                            inset
                            vertical
                          >
                          </v-divider>
                          <v-spacer></v-spacer>
                          <v-dialog
                            v-model="dialogPro"
                            max-width="800px"
                            scrollable
                          >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="#fec5bb"
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="createPro()"
                            >
                            Tambah Proses
                            </v-btn>
                          </template>
                          <v-card>
                            <v-toolbar dark color="#fec5bb">
                              <v-btn icon dark @click="dialogPro = false">
                                <v-icon style="color: #001219;">mdi-close</v-icon>
                              </v-btn>
                              <v-toolbar-title style="color: #001219;">{{ formTitlePro }}</v-toolbar-title>
                              <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-progress-linear v-show="progressBarPro"
                              indeterminate
                              color="yellow darken-2"
                              height="25"
                            ></v-progress-linear>
                            <v-card-title>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                  <v-form
                                    ref="formPro"
                                    v-model="valid"
                                    lazy-validation
                                  >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="12"
                                        >
                                          <div class="form-group">
                                            <label>Pengerjaan</label>
                                            <v-text-field
                                            v-model="editedItemPro.pengerjaan"
                                            id="nama"
                                            label="Proses Pengerjaan"
                                            clearable
                                            solo
                                            ></v-text-field>
                                          </div>
                                        </v-col>
                                        <v-col
                                          cols="6"
                                          sm="6"
                                          md="6"
                                          class="pb-0 mb-0"
                                        >
                                        <div class="form-group">
                                          <label>Tanggal Mulai Pengerjaan</label>
                                          <v-menu
                                            v-model="menu7"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                          >
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-text-field
                                                v-model="editedItemPro.tgl_mulai_baru"
                                                label="Tanggal Mulai Pengerjaan"
                                                prepend-icon="mdi-calendar"
                                                solo
                                                required
                                                v-bind="attrs"
                                                v-on="on"
                                              ></v-text-field>
                                            </template>
                                            <v-date-picker
                                              v-model="tgl_mulai_sementara"
                                              @input="menu7 = false"
                                              id="tgl_mulai"
                                            ></v-date-picker>
                                          </v-menu>
                                        </div>
                                        </v-col>
                                        <v-col
                                          cols="6"
                                          sm="6"
                                          md="6"
                                          class="pb-0 mb-0"
                                        >
                                        <div class="form-group">
                                          <label>Tanggal Akhir</label>
                                          <v-menu
                                            v-model="menu8"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                          >
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-text-field
                                                v-model="editedItemPro.tgl_akhir_baru"
                                                label="Tanggal Akhir"
                                                prepend-icon="mdi-calendar"
                                                solo
                                                required
                                                v-bind="attrs"
                                                v-on="on"
                                              ></v-text-field>
                                            </template>
                                            <v-date-picker
                                              v-model="tgl_akhir_sementara"
                                              @input="menu8 = false"
                                              id="tgl_akhir"
                                            ></v-date-picker>
                                          </v-menu>
                                        </div>
                                        </v-col>
                                        <v-col
                                          cols="6"
                                          sm="6"
                                          md="6"
                                        >
                                          <div class="form-group">
                                            <label>Status Pengerjaan</label>
                                             <v-select
                                                v-model="editedItemPro.status"
                                                :items="itemStatus"
                                                item-value="id"
                                                item-text="status"
                                                label="Status Pengerjaan"
                                                clearable
                                                solo
                                                required
                                              ></v-select>
                                          </div>
                                        </v-col>
                                        <v-col
                                          cols="6"
                                          sm="6"
                                          md="6"
                                          class="pb-0 mb-0"
                                        >
                                        <div class="form-group">
                                          <label>Ditugaskan</label>
                                              <!-- v-show="access.creators.is_show"
                                              :disabled="!access.creators.is_editable" -->
                                            <v-autocomplete
                                              :items="cUser"
                                              v-model="editedItemPro.id_user"
                                              :return-object="true"
                                              id="id_user"
                                              item-text="name"
                                              item-value="id"
                                              label="Ditugaskan"
                                              @keypress="onPressPro"
                                              @keyup.delete="onPressPro"
                                              :search-input.sync="searchComboUser"
                                              :no-filter = false
                                              :hide-no-data="NoDataCUser"
                                              :no-data-text="'Data Tidak Ditemukan'"
                                              @focus="focusAutocompletePro"
                                              :rules="idFormBiasa"
                                              solo
                                              required
                                              multiple
                                            >
                                              <template v-slot:append-item>
                                              <div v-intersect="onIntersectPro">
                                                  <v-list-item v-if="textLoadCUser">
                                                  <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{textLoadCUser}}
                                                    </v-list-item-title>
                                                  </v-list-item-content>
                                                </v-list-item>
                                              </div>
                                              </template>
                                            </v-autocomplete>
                                        </div>
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          sm="12"
                                          md="12"
                                        >
                                          <div class="form-group">
                                            <label>keterangan</label>
                                            <v-text-field
                                              v-model="editedItemPro.keterangan"
                                              id="keterangan"
                                              label="Keterangan"
                                              clearable
                                              solo
                                            ></v-text-field>
                                          </div>
                                        </v-col>
                                    </v-row>
                                  </v-form>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                    <v-btn
                                        rounded
                                        color="#f28482"
                                        @click="closePro()"
                                    >
                                        Batal
                                    </v-btn>
                                    <v-btn
                                        rounded
                                        color="#fec5bb"
                                        @click="savePro(editedItemPro)"
                                    >
                                        Simpan
                                    </v-btn>
                            </v-card-actions>
                          </v-card>
                          </v-dialog>
                              <v-dialog v-model="dialogDeletePro" transition="dialog-bottom-transition" max-width="300">
                                  <v-card>
                                      <v-card-title class="text-h5">Peringatan !</v-card-title>
                                      <v-card-text class="text-h6">Hapus Data ?</v-card-text>
                                      <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="blue darken-1" text @click="closeDeletePro()">Cancel</v-btn>
                                      <v-btn color="#ffa69e" text @click="deleteItemConfirmPro(editedItemPro.id)">OK</v-btn>
                                      <v-spacer></v-spacer>
                                      </v-card-actions>
                                  </v-card>
                              </v-dialog>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn
                            rounded
                            small
                            class="col-md-12 mt-2 mr-2 mb-2"
                            @click="editItemPro(item)"
                            color="#fec5bb"
                        >
                            Edit Data
                        </v-btn>
                        <v-btn
                            rounded
                            class="col-md-12 mb-2"
                            small
                            @click="deleteItemPro(item)"
                            color="#f28482"
                        >
                            Hapus Data
                        </v-btn>
                    </template>
                    
                    <template v-slot:no-data>
                        <!-- <v-btn
                            color="primary"
                            @click="initialize"
                        >
                            Reset
                        </v-btn> -->
                    </template>
                  </v-data-table>
                </div>
              <!--end: Wizard Step 3-->

              <!-- <div class="row justify-content-center py-4 px-4 py-lg-7 px-lg-5">
                <div class="col-xl-12 col-xxl-7"> -->
                    <!--begin: Wizard Step 2-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <v-data-table
                        :headers="headerDokumen"
                        :items="dDokumen"
                        :options.sync="options"
                        :server-items-length="tdDokumen"
                        :loading="loadingTable"
                        loading-text="Proses Data..."
                        class="elevation-1"
                        :footer-props="{
                            'items-per-page-text':'Data Per Halaman',
                            'items-per-page-options': [5, 25, 50, 100]
                        }"
                      >
                      <template v-slot:[`item.number`]="{ index }">
                        <td align="center">{{(options.page * options.itemsPerPage) - options.itemsPerPage + (index + 1)}}</td>
                      </template>
                      <template v-slot:[`item.reminder`]="{ item }">
                        <v-chip
                          color="#8b728e"
                          dark
                        >
                          {{ item.reminder_fe }}
                        </v-chip>
                      </template>
                      <template v-slot:[`item.nama_file`]="{ item }">
                        <v-btn
                          v-show="item.buttonTable"
                          color="#efcfe3"
                          @click="openWeb(item.nama_file)"
                        ><span>Buka File</span></v-btn>
                          <!-- <td v-if="item.source_type_fe == 'RFID'" align="center">{{item.source}}</td> -->
                            <!-- <vue-pdf-app :pdf="baseUrlUpload + '/' +item.source"></vue-pdf-app> -->
                      </template>
                        <template v-slot:top>
                          <v-toolbar flat>
                              <v-toolbar-title><b>Dokumen Pengurusan Perseroan</b></v-toolbar-title>
                              <v-divider
                                class="mx-4"
                                inset
                                vertical
                              >
                              </v-divider>
                              <v-spacer></v-spacer>
                              <v-dialog
                                v-model="dialog"
                                max-width="800px"
                                scrollable
                              >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="#fec5bb"
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="createDok()"
                                >
                                Tambah Dokumen
                                </v-btn>
                              </template>
                              <v-card>
                                <v-toolbar dark color="#fec5bb">
                                  <v-btn icon dark @click="dialog = false">
                                    <v-icon style="color: #001219;">mdi-close</v-icon>
                                  </v-btn>
                                  <v-toolbar-title style="color: #001219;">{{ formTitle }}</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                </v-toolbar>
                                <v-progress-linear v-show="progressBar"
                                  indeterminate
                                  color="yellow darken-2"
                                  height="25"
                                ></v-progress-linear>
                                <v-card-title>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                      <v-form
                                        ref="formDok"
                                        v-model="valid"
                                        lazy-validation
                                      >
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                sm="12"
                                                md="12"
                                            >
                                              <div class="form-group">
                                                <label>Nama Dokumen</label>
                                                <v-text-field
                                                v-model="editedItem.nama_dokumen"
                                                id="nama_dokumen"
                                                label="Nama Dokumen"
                                                clearable
                                                solo
                                                ></v-text-field>
                                              </div>
                                            </v-col>
                                            <v-col
                                              cols="6"
                                              sm="6"
                                              md="6"
                                              class="pb-0 mb-0"
                                            >
                                            <div class="form-group">
                                              <label>Tanggal Terima Dokumen</label>
                                              <v-menu
                                                v-model="menu5"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                              >
                                                <template v-slot:activator="{ on, attrs }">
                                                  <v-text-field
                                                    v-model="editedItem.tgl_terima_baru"
                                                    label="Tanggal Terima Dokumen"
                                                    prepend-icon="mdi-calendar"
                                                    solo
                                                    required
                                                    v-bind="attrs"
                                                    v-on="on"
                                                  ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                  v-model="tgl_terima_sementara"
                                                  @input="menu5 = false"
                                                  id="tgl_terima"
                                                ></v-date-picker>
                                              </v-menu>
                                            </div>
                                            </v-col>
                                            <v-col
                                              cols="6"
                                              sm="6"
                                              md="6"
                                              class="pb-0 mb-0"
                                            >
                                            <div class="form-group">
                                              <label>Berlaku Sampai Dengan</label>
                                              <v-menu
                                                v-model="menu6"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                              >
                                                <template v-slot:activator="{ on, attrs }">
                                                  <v-text-field
                                                    v-model="editedItem.tgl_exp_baru"
                                                    label="Berlaku Sampai Dengan"
                                                    prepend-icon="mdi-calendar"
                                                    solo
                                                    required
                                                    v-bind="attrs"
                                                    v-on="on"
                                                  ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                  v-model="tgl_exp_sementara"
                                                  @input="menu6 = false"
                                                  id="tgl_exp"
                                                ></v-date-picker>
                                              </v-menu>
                                            </div>
                                            </v-col>
                                            <!-- <div class="form-group"> -->
                                              <!-- <label>Pilih File</label> -->
                                              <v-col
                                                  cols="6"
                                                  sm="6"
                                                  md="6"
                                              >
                                              <div class="form-group">
                                                <v-file-input
                                                  v-show="!alert_file"
                                                  label="Sumber File"
                                                  accept="file*"
                                                  id="source_upload"
                                                  v-model="editedItem.source_upload"
                                                  prepend-icon="mdi-file"
                                                  @click:clear="removeInputPath"
                                                  @change="onFileChange"
                                                  ref="inputFile"
                                                  solo
                                                >
                                              </v-file-input>
                                                <v-alert
                                                  v-model="alert_file"
                                                  dark
                                                  dismissible
                                                  border="left"
                                                  color="deep-blue accent-4"
                                                  v-show="alert_file"
                                                ><span>{{editedItem.nama_file_conv}}</span>
                                                  <template v-slot:close="{toggle}">
                                                    <v-btn @click="doHapusFile(toggle)">Hapus File</v-btn>
                                                  </template>
                                                </v-alert>
                                              </div>
                                              </v-col>
                                              <v-col
                                                cols="6"
                                                sm="6"
                                                md="6"
                                              >
                                                <div class="form-group">
                                                  <!-- <label>Berlaku Sampai Dengan</label> -->
                                                    <v-checkbox
                                                        v-model="editedItem.reminder"
                                                        label="Ingatkan"
                                                        color="indigo darken-3"
                                                        hide-details
                                                    ></v-checkbox>
                                                </div>
                                              </v-col>
                                            <!-- </div> -->
                                        </v-row>
                                      </v-form>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                        <v-btn
                                            rounded
                                            color="#f28482"
                                            @click="close()"
                                        >
                                            Batal
                                        </v-btn>
                                        <v-btn
                                            rounded
                                            color="#fec5bb"
                                            @click="saveDok(editedItem)"
                                        >
                                            Simpan
                                        </v-btn>
                                </v-card-actions>
                              </v-card>
                              </v-dialog>
                                  <v-dialog v-model="dialogDelete" transition="dialog-bottom-transition" max-width="300">
                                      <v-card>
                                          <v-card-title class="text-h5">Peringatan !</v-card-title>
                                          <v-card-text class="text-h6">Hapus Data ?</v-card-text>
                                          <v-card-actions>
                                          <v-spacer></v-spacer>
                                          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                          <v-btn color="#ffa69e" text @click="deleteItemConfirm(editedItem.id)">OK</v-btn>
                                          <v-spacer></v-spacer>
                                          </v-card-actions>
                                      </v-card>
                                  </v-dialog>
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                rounded
                                small
                                class="col-md-12 mt-2 mr-2 mb-2"
                                @click="editItem(item)"
                                color="#fec5bb"
                            >
                                Edit Data
                            </v-btn>
                            <v-btn
                                rounded
                                class="col-md-12 mb-2"
                                small
                                @click="deleteItem(item)"
                                color="#f28482"
                            >
                                Hapus Data
                            </v-btn>
                        </template>
                        
                        <template v-slot:no-data>
                            <!-- <v-btn
                                color="primary"
                                @click="initialize"
                            >
                                Reset
                            </v-btn> -->
                        </template>
                      </v-data-table>
                    </div>
                    <!--end: Wizard Step 2-->

                     <!--begin: Wizard Step 3-->
                    <!-- <v-progress-linear
                      indeterminate
                      color="pink darken-2"
                      v-show="!loadFormAkad"
                      style="height:15px;"
                    ><div><span>Proses Data...</span></div></v-progress-linear> -->
                    <div class="pb-5" data-wizard-type="step-content">
                      <v-form
                        ref="formAkad"
                        v-model="valid"
                        lazy-validation
                        v-show="loadFormAkad"
                      >
                        <v-row>
                            <v-col
                              cols="6"
                              sm="6"
                              md="6"
                              class="pb-0 mb-0"
                            >
                            <div class="form-group">
                              <label>Tanggal Akad</label>
                              <v-menu
                                v-model="menu9"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editedItemAkad.tgl_akad_baru"
                                    label="Tanggal Akad"
                                    prepend-icon="mdi-calendar"
                                    solo
                                    required
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="tgl_akad_sementara"
                                  @input="menu9 = false"
                                  id="tgl_akad"
                                ></v-date-picker>
                              </v-menu>
                            </div>
                            </v-col>
                            <v-col
                              cols="6"
                              sm="6"
                              md="6"
                              class="pb-0 mb-0"
                            >
                            <div class="form-group">
                              <label>Waktu</label>
                              <v-menu
                                v-model="menu10"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editedItemAkad.jam_akad_baru"
                                    label="Waktu"
                                    :rules="idFormBiasa"
                                    prepend-icon="mdi-clock"
                                    solo
                                    required
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                  v-model="jam_akad_sementara"
                                  format="24hr"
                                  scrollable
                                  @input="menu10 = false"
                                  id="jam_akad"
                                ></v-time-picker>
                              </v-menu>
                            </div>
                            </v-col>
                            <v-col
                                cols="6"
                                sm="6"
                                md="6"
                            >
                              <div class="form-group">
                                <label>Lokasi</label>
                                <v-text-field
                                prepend-icon="mdi-map"
                                v-model="editedItemAkad.lokasi_akad"
                                id="lokasi_akad"
                                label="Lokasi"
                                clearable
                                solo
                                ></v-text-field>
                              </div>
                            </v-col>
                            <v-col
                              cols="6"
                              sm="6"
                              md="6"
                            >
                              <div class="form-group">
                                <label>Nomor Akte</label>
                                <v-text-field
                                v-model="editedItemAkad.no_akte"
                                id="no_akte"
                                label="Nomor Akte"
                                clearable
                                solo
                                ></v-text-field>
                              </div>
                            </v-col>
                            <v-col
                              cols="6"
                              sm="6"
                              md="6"
                              class="pb-0 mb-0"
                            >
                            <div class="form-group">
                              <label>Tanggal Akte</label>
                              <v-menu
                                v-model="menu11"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editedItemAkad.tgl_akte_baru"
                                    label="Tanggal Akte"
                                    prepend-icon="mdi-calendar"
                                    solo
                                    required
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="tgl_akte_sementara"
                                  @input="menu11 = false"
                                  id="tgl_akte"
                                ></v-date-picker>
                              </v-menu>
                            </div>
                            </v-col>
                            <v-col
                              cols="6"
                              sm="6"
                              md="6"
                              class="pb-0 mb-0"
                            >
                            <div class="form-group">
                              <label>Masa Berlaku Hingga</label>
                              <v-menu
                                v-model="menu12"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editedItemAkad.tgl_akhir_akte_baru"
                                    label="Masa Berlaku Hingga"
                                    prepend-icon="mdi-calendar"
                                    solo
                                    required
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="tgl_akhir_akte_sementara"
                                  @input="menu12 = false"
                                  id="tgl_akhir_akte"
                                ></v-date-picker>
                              </v-menu>
                            </div>
                            </v-col>
                            <!-- <v-col
                              cols="6"
                              sm="6"
                              md="6"
                            >
                              <div class="form-group">
                                <label>NPWP</label>
                                <v-text-field
                                  v-model="editedItemAkad.npwp"
                                  id="npwp"
                                  label="NPWP"
                                  clearable
                                  solo
                                ></v-text-field>
                              </div>
                            </v-col>
                            <v-col
                              cols="6"
                              sm="6"
                              md="6"
                            >
                              <div class="form-group">
                                <label>NIB</label>
                                <v-text-field
                                  v-model="editedItemAkad.nib"
                                  id="nib"
                                  label="NIB"
                                  clearable
                                  solo
                                ></v-text-field>
                              </div>
                            </v-col>
                            <v-col
                              cols="6"
                              sm="6"
                              md="6"
                            >
                              <div class="form-group">
                                <label>SK Kemenkumham</label>
                                <v-text-field
                                  v-model="editedItemAkad.no_sk"
                                  id="no_sk"
                                  label="SK Kemenkumham"
                                  clearable
                                  solo
                                ></v-text-field>
                              </div>
                            </v-col>
                            <v-col
                              cols="6"
                              sm="6"
                              md="6"
                              class="pb-0 mb-0"
                            >
                            <div class="form-group">
                              <label>Tanggal SK Kemenkumham</label>
                              <v-menu
                                v-model="menu13"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editedItemAkad.tgl_sk_baru"
                                    label="Tanggal SK Kemenkumham"
                                    prepend-icon="mdi-calendar"
                                    solo
                                    required
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="tgl_sk_sementara"
                                  @input="menu13 = false"
                                  id="tgl_sk"
                                ></v-date-picker>
                              </v-menu>
                            </div>
                            </v-col> -->
                        </v-row>
                      </v-form>
                    </div>
                    <!--end: Wizard Step 3-->

                    <!--begin: Wizard Actions -->
                      <div class="d-flex justify-content-between border-top pt-10" v-show="loadForm">
                        <div class="mr-2">
                          <v-btn
                              rounded
                              color="#ffa69e"
                              style="border-radius: 30px; border: 2px solid white; font-size: 13px; height: 50px;"
                              @click="goTable()"
                          >
                              Kembali Ke Table
                          </v-btn>
                        </div>
                      <div>
                         <v-btn
                              v-show="showSaveForm"
                              rounded
                              color="#fec5bb"
                              style="border-radius: 30px; border: 2px solid white; font-size: 13px; height: 50px;"
                              @click="save(formItem)"
                          >
                              {{textUnderButton}}
                          </v-btn>
                          <v-btn
                              rounded
                              color="#fec5bb"
                              style="border-radius: 30px; border: 2px solid white; font-size: 13px; height: 50px;"
                              data-wizard-type="action-submit"
                              @click="saveAkad(editedItemAkad)"
                          >
                              Simpan
                          </v-btn>
                      </div>
                    </div>
                    <!--end: Wizard Actions -->
                  <!-- </form> -->
                  <!--end: Wizard Form-->
                </div>
              </div>
            </div>
          </div>
        </v-app>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
    
  </div>
</template>

<style lang="css">
    .v-application--wrap{ min-height: 0px; }
    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        background-color: #ffa69e;
        font-size: 13px;
        vertical-align: inherit;
        margin-top: 5%;
    }
</style>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import Services from "@/core/services/notasis-api/Services";
import storagelocal from "@/core/services/storagelocal.service";

export default {
  name: "Wizard-4",
  data(){
    return{
      valid: true,
      formItem: {
        tgl_order: '',
        tgl_order_baru: '',
        jenis_pengurusan: '',
        id_notaris_kerjasama: '',
        transaksi_dtl: '',
        kredit_dtl: '',
        sertifikat_dtl: '',
        transaksi_lain: '',
        kredit_lain: '',
        sertifikat_lain: '',
        fidusia_dtl: '',
        id_nasabah: '',
        id_penjamin: '',
        bank: '',
        cabang: '',
        deskripsi: '',
        tgl_akhir_pengurusan: '',
        tgl_akhir_pengurusan_baru: '',
        reminder: ''
      },
      rTransaksi: 1,
      rKredit: 2,
      rSertifikat: 3,
      rPengurusanLain: null,
      isTransaksi: false,
      isKredit: false,
      isSertifikat: false,
      childTransaksi: false,
      childSertifikat: false,
      childKredit: false,
      childKredit2: false,
      idPenjamin: '',
      itemPenjamin: [],
      itemNotaris: [],
      childPengurusan: false,
      itemTransaksi: [
        {id: 1, transaksi: "AJB"},
        {id: 2, transaksi: "PPJB"},
        {id: 3, transaksi: "Sewa-Menyewa"},
        {id: 4, transaksi: "Warmeking"},
        {id: 5, transaksi: "Legalisasi"},
        {id: 6, transaksi: "Roya / Cessi"},
        {id: 8, transaksi: "Waris"},
        {id: 7, transaksi: "Lainnya"},
      ],
      itemKredit: [
        {id: 1, kredit: "Perjanjian Kredit"},
        {id: 2, kredit: "Personal Guarantee"},
        {id: 3, kredit: "Fidusia"},
        {id: 4, kredit: "APHT"},
        {id: 5, kredit: "SMHT"},
        {id: 6, kredit: "Lainnya"},
      ],
      itemSertifikat: [
        {id: 1, sertifikat: "Perpanjangan"},
        {id: 2, sertifikat: "Pembaharuan"},
        {id: 3, sertifikat: "Peningkatan Hak"},
        {id: 4, sertifikat: "Penurunan Hak"},
        {id: 5, sertifikat: "Ganti Blanko"},
        {id: 6, sertifikat: "Balik Nama"},
        {id: 7, sertifikat: "Pengecekan"},
        {id: 8, sertifikat: "Pajak"},
        {id: 9, sertifikat: "Lainnya"},
      ],
      itemFidusia: [
        {id: 1, fidusia: "AR / Piutang"},
        {id: 2, fidusia: "Kendaraan"},
        {id: 3, fidusia: "Alat Berat"},
        {id: 4, fidusia: "Mesin"},
        {id: 5, fidusia: "Inventory"},
      ],
      itemJenisPengurusan: [
        {id: 1, jenis: "Kerjasama"},
        {id: 2, jenis: "Pribadi"},
        {id: 3, jenis: "Bank"},
        {id: 4, jenis: "Developer"},
      ],
      editedItem: {
        id: '',
        nama_dokumen: '',
        tgl_terima: '',
        tgl_terima_baru: '',
        tgl_exp: '',
        tgl_exp_baru: '',
        reminder: 0,
        source_upload: null,
        nama_file: '',
        nama_file_conv: '',
      },
      editedItemAkad: {
        id: '',
        tgl_akad: '',
        tgl_akad_baru: '',
        jam_akad: '',
        jam_akad_baru: '',
        lokasi_akad: '',
        no_akte: '',
        tgl_akte: '',
        tgl_akte_baru: '',
        tgl_akhir_akte: '',
        tgl_akhir_akte_baru: '',
        // npwp: '',
        // nib: '',
        // no_sk: '',
        // tgl_sk: '',
        // tgl_sk_baru: '',
      },
      editedItemPro: {
        id: '',
        pengerjaan: '',
        tgl_mulai: '',
        tgl_mulai_baru: '',
        tgl_akhir: '',
        tgl_akhir_baru: '',
        status: '',
        id_user: [],
        keterangan: '',
        comment: '',
      },
      defaultItem: {
        nama_dokumen: '',
        tgl_terima: '',
        tgl_terima_baru: '',
        tgl_exp: '',
        tgl_exp_baru: '',
        reminder: 0,
        source_upload: null,
        nama_file: '',
        nama_file_conv: ''
      },
      defaultItemAkad: {
        id: '',
        tgl_akad: '',
        jam_akad: '',
        lokasi_akad: '',
        no_akte: '',
        tgl_akte: '',
        tgl_akhir_akte: '',
        npwp: '',
        nib: '',
        no_sk: '',
        tgl_sk: '',
      },
      defaultItemPro: {
        id: '',
        pengerjaan: '',
        tgl_mulai: '',
        tgl_mulai_baru: '',
        tgl_akhir: '',
        tgl_akhir_baru: '',
        status: '',
        id_user: [],
        keterangan: '',
        comment: ''
      },
      itemStatus: [
        {id: 1, status: "Pending"},
        {id: 2, status: "On Progress"},
        {id: 3, status: "Selesai"},
      ],
      idFormBiasa: [
        v => !!v || 'Mohon Diisi !'
      ],
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      menu8: false,
      menu9: false,
      menu10: false,
      menu11: false,
      menu12: false,
      menu13: false,
      menu14: false,
      // tgl_akte_sementara: null,
      // tgl_sk_sementara: null,
      tgl_order_sementara: null,
      tgl_terima_sementara: null,
      tgl_exp_sementara: null,
      tgl_mulai_sementara: null,
      tgl_akhir_sementara: null,
      
      tgl_akad_sementara: null,
      tgl_akte_sementara: null,
      tgl_akhir_akte_sementara: null,
      tgl_sk_sementara: null,
      tgl_akhir_pengurusan_sementara: null,
      jam_akad_sementara: null,
      flag: "",
      // ableWizard: true,
      idData: '',
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false
      },
      optionsAkad: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false
      },
      optionsPro: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false
      },
      headerDokumen: [
        {
          text: 'Id',
          // align: 'start',
          sortable: false,
          value: 'id',
          align: ' d-none'
        },
        { text: 'NO', value: 'number', align: 'center', sortable: false, class:'black--text text--darken-4' },
        { text: 'DOKUMEN', value: 'nama_dokumen', sortable: false, align: 'center', class:'black--text text--darken-4' },
        { text: 'TGL TERIMA', value: 'tgl_terima_fe', class:'black--text text--darken-4', sortable: false, },
        { text: 'BERLAKU SAMPAI', value: 'tgl_exp_fe', class:'black--text text--darken-4', sortable: false, },
        { text: 'INGATKAN', value: 'reminder', class:'black--text text--darken-4', sortable: false, },
        { text: 'NAMA FILE', value: 'nama_file', class:'black--text text--darken-4', sortable: false, },
        { text: 'AKSI', value: 'actions', sortable: false, width: 5, align: 'center', class:'black--text text--darken-4' },
      ],
      headerProses: [
        {
          text: 'Id',
          // align: 'start',
          sortable: false,
          value: 'id',
          align: ' d-none'
        },
        { text: 'NO', value: 'number', align: 'center', sortable: false, class:'black--text text--darken-4' },
        { text: 'PENGERJAAN', value: 'pengerjaan', sortable: false, align: 'center', class:'black--text text--darken-4' },
        { text: 'TGL TERIMA', value: 'tgl_mulai_fe', class:'black--text text--darken-4', sortable: false, },
        { text: 'STATUS', value: 'status_fe', class:'black--text text--darken-4', sortable: false, },
        { text: 'TGL AKHIR', value: 'tgl_akhir_fe', class:'black--text text--darken-4', sortable: false, },
        { text: 'DITUGASKAN', value: 'name_users', class:'black--text text--darken-4', sortable: false, },
        { text: 'SARAN', value: 'comment', class:'black--text text--darken-4', sortable: false, },
        { text: 'KETERANGAN', value: 'keterangan', class:'black--text text--darken-4', sortable: false, },
        { text: 'AKSI', value: 'actions', sortable: false, width: 5, align: 'center', class:'black--text text--darken-4' },
      ],
      dDokumen: [],
      tdDokumen: 0,
      loadingTable: false,
      dialog: false,
      progressBar: false,
      alert_file: false,
      editedIndex: -1,
      dialogDelete: false,
      is_source_remove: 0,  
      baseUrlUpload: ApiService.getBaseUrlUpload(),
      loadForm: false,
      isFirstLoad: true,

      dAkad: [],
      tdAkad: 0,
      loadFormAkad: false,
      progressBarAkad: false,
      isFirstLoadAkad: true,
      cNasabah: [],
      ctNasabah: 0,
      id_cNasabah: '',
      options_combo: {
        page: 1, 
        itemsPerPage: 7, 
        sortBy: ['nama'],
        sortDesc: [], 
        groupBy: [], 
        groupDesc: [],
        multiSort: true, 
        mustSort: false 
      },
      options_combo_nasabah: {
        page: 1, 
        itemsPerPage: 7, 
        sortBy: ['nama'],
        sortDesc: [], 
        groupBy: [], 
        groupDesc: [],
        multiSort: true, 
        mustSort: false 
      },
      options_combo_penjamin: {
        page: 1, 
        itemsPerPage: 7, 
        sortBy: ['nama'],
        sortDesc: [], 
        groupBy: [], 
        groupDesc: [],
        multiSort: true, 
        mustSort: false 
      },
      options_combo_notaris: {
        page: 1, 
        itemsPerPage: 7, 
        sortBy: ['nama'],
        sortDesc: [], 
        groupBy: [], 
        groupDesc: [],
        multiSort: true, 
        mustSort: false 
      },
      search: '',
      totalCombo: 0,
      textLoad: 'Sedang Memuat ...',
      NoData : true,
      search_page : '',

      dProses: [],
      tdProses: 0,
      loadingTablePro: false,
      dialogPro: false,
      progressBarPro: false,
      editedIndexPro: -1,
      dialogDeletePro: false,
      isFirstLoadPro: true,
      cUser: [],
      ctUser: 0,
      id_cUser: '',
      options_combo_user: {
        page: 1, 
        itemsPerPage: 7, 
        sortBy: ['name'],
        sortDesc: [], 
        groupBy: [], 
        groupDesc: [],
        multiSort: true, 
        mustSort: false 
      },
      searchComboUser: '',
      totalCUser: 0,
      textLoadCUser: 'Sedang Memuat ...',
      NoDataCUser : true,
      id_nasabah : '',
      id_create: '',
      progressForm: false,
      textUnderButton: 'Simpan',
      id_nasabah_create: '',
      showSaveForm: true
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Wizard", route: "wizard-1" },
      { title: "Wizard-4" }
    ]);
    
    // console.log(this.$route.query.dPerseroan)
    this.flag = this.$route.query.flag

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: this.flag == "isUpdate" ? true : false // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function(/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  watch: {
    options: {
        async handler (val) {
            val
            if(!this.isFirstLoad) 
              await this.getDataDokumen()
        },
        deep: true,
    },
    optionsAkad: {
        async handler (val) {
            val
            if(!this.isFirstLoadAkad) 
              await this.getDataPenjamin()
        },
        deep: true,
    },
    optionsPro: {
        async handler (val) {
            val
            if(!this.isFirstLoadPro) 
              await this.getDataProses()
        },
        deep: true,
    },
    // tgl_akte_sementara(val){
    //   this.formItem.tgl_akte = val 
    //   this.formItem.tgl_akte_baru = this.converter(val)
    // },
    // tgl_sk_sementara(val){
    //   this.formItem.tgl_sk = val 
    //   this.formItem.tgl_sk_baru = this.converter(val)
    // },
    tgl_order_sementara(val){
      this.formItem.tgl_order = val 
      this.formItem.tgl_order_baru = this.converter(val)
    },
    tgl_mulai_sementara(val){
      this.editedItemPro.tgl_mulai = val 
      this.editedItemPro.tgl_mulai_baru = this.converter(val)
    },
    tgl_akhir_sementara(val){
      this.editedItemPro.tgl_akhir = val 
      this.editedItemPro.tgl_akhir_baru = this.converter(val)
    },
    tgl_terima_sementara(val){
      this.editedItem.tgl_terima = val 
      this.editedItem.tgl_terima_baru = this.converter(val)
    },
    tgl_exp_sementara(val){
      this.editedItem.tgl_exp = val 
      this.editedItem.tgl_exp_baru = this.converter(val)
    },
    jam_akad_sementara(val){
      this.editedItemAkad.jam_akad = val 
      this.editedItemAkad.jam_akad_baru = val
    },
    tgl_akad_sementara(val){
      this.editedItemAkad.tgl_akad = val 
      this.editedItemAkad.tgl_akad_baru = this.converter(val)
    },
    tgl_akte_sementara(val){
      this.editedItemAkad.tgl_akte = val 
      this.editedItemAkad.tgl_akte_baru = this.converter(val)
    },
    tgl_akhir_akte_sementara(val){
      this.editedItemAkad.tgl_akhir_akte = val 
      this.editedItemAkad.tgl_akhir_akte_baru = this.converter(val)
    },
    tgl_sk_sementara(val){
      this.editedItemAkad.tgl_sk = val 
      this.editedItemAkad.tgl_sk_baru = this.converter(val)
    },
    tgl_akhir_pengurusan_sementara(val){
      this.formItem.tgl_akhir_pengurusan = val 
      this.formItem.tgl_akhir_pengurusan_baru = this.converter(val)
    },
    flag(val){
      if(val == 'isCreate'){
        this.isCreate()
      }else if(val == 'isUpdate' && this.isFirstLoad && this.isFirstLoadPro){
        this.idData = this.$route.query.idData
        this.id_nasabah = this.$route.query.idNasabah
        this.load2()
      }
    },
    search(val){
      this.search = val
    }, 
    cNasabah(val){
      if(val.length == this.ctNasabah)
      this.textLoad = ''
    },
    searchComboUser(val){
      this.searchComboUser = val
    }, 
    cUser(val){
      if(val.length == this.ctUser)
      this.textLoadCUser = ''
    },
    dialogDeletePro (val) {
      val || this.closeDeletePro()
    },
    rPengurusanLain(val){
      if(val == 1){
        this.doTransaksi()
      }
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Tambah Dokumen' : 'Edit Dokumen'
    },
    formTitlePro () {
      return this.editedIndex === -1 ? 'Tambah Proses Pengerjaan' : 'Edit Proses Pengerjaan'
    },
  },
  methods: {
    submit: function(e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });
    },

    /// load first //////////////////////////////////////
    async isCreate(){
      var date_now = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.tgl_order_sementara = date_now
      this.formItem.tgl_order_baru = this.converter(this.tgl_order_sementara)
      this.formItem.tgl_order = this.converterBe(this.tgl_order_sementara)
      this.tgl_akhir_pengurusan_sementara = date_now
      this.formItem.tgl_akhir_pengurusan_baru = this.converter(this.tgl_akhir_pengurusan_sementara)
      this.tgl_terima_sementara = date_now
      this.editedItem.tgl_terima_baru = this.converter(this.tgl_terima_sementara)
      this.editedItem.tgl_terima = this.converterBe(this.tgl_terima_sementara)
      this.tgl_exp_sementara = date_now
      this.editedItem.tgl_exp_baru = this.converter(this.tgl_exp_sementara)
      this.editedItem.tgl_exp = this.converterBe(this.tgl_exp_sementara)

      this.tgl_akad_sementara = date_now
      this.editedItemAkad.tgl_akad_baru = this.converter(this.tgl_akad_sementara)
      this.editedItemAkad.tgl_akad = this.converterBe(this.tgl_akad_sementara)
      this.tgl_akte_sementara = date_now
      this.editedItemAkad.tgl_akte_baru = this.converter(this.tgl_akte_sementara)
      this.editedItemAkad.tgl_akte = this.converterBe(this.tgl_akte_sementara)
      this.tgl_akhir_akte_sementara = date_now
      this.editedItemAkad.tgl_akhir_akte_baru = this.converter(this.tgl_akhir_akte_sementara)
      this.editedItemAkad.tgl_akhir_akte = this.converterBe(this.tgl_akhir_akte_sementara)
      this.tgl_sk_sementara = date_now
      this.editedItemAkad.tgl_sk_baru = this.converter(this.tgl_sk_sementara)
      this.editedItemAkad.tgl_sk = this.converterBe(this.tgl_sk_sementara)
      this.loadForm = true
      this.loadFormAkad = true
    },
    async load2(){
      Promise.all([
                    await this.getDataPengurusanLain(),
                    await this.getDataProses(),
                    await this.getDataDokumen(),
                    await this.getDataAkad()
                  ])
      .then( (results)=> {
        
        this.isFirstLoad = false
        this.isFirstLoadPro = false
        results
      })
    },
    // async isUpdate(){
    //   await this.getDataNasabah()
    //   await this.getDataDokumen()
    // },
    converter(date){
      var conv_date = new Date(date);
      var dd = String(conv_date.getDate()).padStart(2, '0');
      var mm = String(conv_date.getMonth() + 1).padStart(2, '0'); 
      var yyyy = conv_date.getFullYear();

      return dd + '-' + mm + '-' + yyyy;
    },
    converterFe(date){
      var conv_date = new Date(date);
      var dd = String(conv_date.getDate()).padStart(2, '0');
      var mm = String(conv_date.getMonth() + 1).padStart(2, '0'); 
      var yyyy = conv_date.getFullYear();

      return dd + '-' + mm + '-' + yyyy;
    },
    converterBe(date){
      var conv_date = new Date(date);
      var dd = String(conv_date.getDate()).padStart(2, '0');
      var mm = String(conv_date.getMonth() + 1).padStart(2, '0'); 
      var yyyy = conv_date.getFullYear();

      return yyyy + '-' + mm + '-' + dd;
    },

    /////// form Utama /////////////////////////////////
    async save (data_save){
      var saveObj = {}
      var wizard = new KTWizard('kt_wizard_v4', {
        startStep: 1, // initial active step number
        clickableSteps: this.flag == "isUpdate" ? true : false
      });
      var _this = this
      if(this.loadForm){
        if( _this.$refs.formUtama.validate()){
          if(this.flag == 'isCreate'){
            saveObj = Object.assign({}, data_save)
            saveObj.reminder = this.formItem.reminder == false ? 0 : 1
            if(this.isTransaksi == true){
              if(data_save.transaksi_dtl != 7){
                saveObj.transaksi_lain = ''
              }
              saveObj.klasifikasi = 1
              saveObj.kredit_dtl = ''
              saveObj.kredit_lain = ''
              saveObj.fidusia_dtl = ''  
              saveObj.sertifikat_dtl = ''
              saveObj.sertifikat_lain = ''
            }else if(this.isKredit == true){
              saveObj.klasifikasi = 2
              saveObj.transaksi_dtl = ''
              saveObj.sertifikat_dtl = ''
              saveObj.transaksi_lain = ''
              saveObj.sertifikat_lain = ''
            }else if(this.isSertifikat == true){
              if(data_save.sertifikat_dtl != 9){
                saveObj.sertifikat_lain = ''
              }
              saveObj.klasifikasi = 3
              saveObj.transaksi_dtl = ''
              saveObj.transaksi_lain = ''
              saveObj.kredit_dtl = ''
              saveObj.kredit_lain = ''
              saveObj.fidusia_dtl = ''  
            }
            saveObj.UID = storagelocal.getLocalStorage('UID')
            saveObj.Token = storagelocal.getLocalStorage('token')
            // saveObj.tgl_akte = null
            saveObj.id_nasabah = typeof data_save.id_nasabah == 'number' ? data_save.id_nasabah : data_save.id_nasabah.id
            saveObj.Trigger = 'C'
          }else{
            saveObj = Object.assign({}, data_save)
            saveObj.reminder = this.formItem.reminder == false ? 0 : 1
            // saveObj.tgl_akad = this.converterBe(saveObj.tgl_akad)
            // saveObj.tgl_akhir_akte = this.converterBe(saveObj.tgl_akhir_akte)
            // saveObj.tgl_akte = this.converterBe(saveObj.tgl_akte)
            if(this.isTransaksi == true){
              if(data_save.transaksi_dtl != 7){
                saveObj.transaksi_lain = ''
              }
              saveObj.klasifikasi = 1
              saveObj.kredit_dtl = ''
              saveObj.kredit_lain = ''
              saveObj.fidusia_dtl = ''  
              saveObj.sertifikat_dtl = ''
              saveObj.sertifikat_lain = ''
            }else if(this.isKredit == true){
              if(data_save.kredit_dtl != 3){
                saveObj.fidusia_dtl = ''
              }else if(data_save.kredit_dtl != 6){
                saveObj.kredit_lain = ''
              }
              saveObj.klasifikasi = 2
              saveObj.transaksi_dtl = ''
              saveObj.sertifikat_dtl = ''
              saveObj.transaksi_lain = ''
              saveObj.sertifikat_lain = ''
            }else if(this.isSertifikat == true){
              if(data_save.sertifikat_dtl != 9){
                saveObj.sertifikat_lain = ''
              }
              saveObj.klasifikasi = 3
              saveObj.transaksi_dtl = ''
              saveObj.transaksi_lain = ''
              saveObj.kredit_dtl = ''
              saveObj.kredit_lain = ''
              saveObj.fidusia_dtl = ''  
            }
            saveObj.UID = storagelocal.getLocalStorage('UID')
            saveObj.Token = storagelocal.getLocalStorage('token')
            // saveObj.tgl_akte = null
            saveObj.Trigger = 'U'
            saveObj.id = this.idData
            for (let i = 0; i <  data_save.id_nasabah.length; i++) {
              if(data_save.id_nasabah[i].id == undefined){
                saveObj.id_nasabah = data_save.id_nasabah[i]  
              }else{
                saveObj.id_nasabah = data_save.id_nasabah[i].id
              }            
            }
            // saveObj.id_nasabah = data_save.id_nasabah.id
          }
          Promise.all([await this.saveAll(saveObj)])
            .then((results8)=>{
            wizard.goNext()
            results8
            this.loadForm = false
            this.textUnderButton = "Selanjutnya"
            // this.loadPage()
          })
        }
      }else{
        if(wizard.getStep() == 3){
          this.showSaveForm = false
        }else{
          this.showSaveForm = true
        }
        wizard.goNext()
      }
    },
    saveAll(saveObj){
      return new Promise(resolve => {
          resolve, saveObj
            // this.ableWizard = false;
            Services.PostData(ApiService, "t_pengurusan_lain", saveObj, async response=>{
              resolve(response.data);
              if(response.status == 1000){
                if(response.data[0] != null){
                  this.id_create = response.data[0].id
                  this.id_nasabah_create = response.data[0].id_nasabah
                  await this.getDataDokumen()
                }
                Swal.fire({
                    title: "Berhasil",
                    text: "Sukses Menambah Data",
                    icon: "success",
                    heightAuto: false,
                    timer: 2000
                });
              }else{
                Swal.fire({
                    title: "Berhasil",
                    text: "Gagal Menambah Data",
                    icon: "info",
                    heightAuto: false,
                    timer: 2000
                });
              }
            }, err =>{
              err
            }) 
        });
    },
    getDataPengurusanLain(){
      return new Promise(resolve => {
      var mydata = {
          UID 		        : storagelocal.getLocalStorage('UID'),
          Token		        : storagelocal.getLocalStorage('token'),
          Trigger	        : 'R',
          options         : this.options,
          id              : this.idData
      }
      this.loadForm = false
      this.progressForm = true
      Services.PostData(ApiService, "t_pengurusan_lain", mydata, async response=>{
        if(response.status == 1000){
          this.loadForm = true
          this.progressForm = false
          var responseCace = response.data[0]
          this.cNasabah = []
          this.options_combo.page = 1
          this.id_cNasabah = responseCace.id_nasabah
          Promise.all([
              await this.getComboNasabah()
          ])
          .then(async (results) => {
            results
            await this.getComboPenjamin()
            this.id_cNasabah = ''
            for (var property in this.formItem) {
              var val = ''
              if(responseCace[property] != null && responseCace[property] != undefined)
                val = responseCace[property]

              this.formItem[property] = val
            }
            // this.formItem = Object.assign({}, responseCace)
            if(responseCace.jenis_pengurusan == 1){
              await this.getComboNotaris()
              this.childPengurusan = true
            }else{
              this.childPengurusan = false
            }
            if(responseCace.klasifikasi == 1){
              this.rPengurusanLain = 1
              this.isTransaksi = true
              this.isKredit = false
              this.isSertifikat = false
              if(responseCace.transaksi_lain != '' && responseCace.transaksi_lain != null){
                this.childTransaksi = true
              }else{
                this.childTransaksi = false
              }
            }else if(responseCace.klasifikasi == 2){
              this.rPengurusanLain = 2
              this.isKredit = true
              this.isSertifikat = false
              this.isTransaksi = false
              this.childKredit = false
              this.childKredit2 = false
              if(responseCace.kredit_lain != '' && responseCace.kredit_lain != null){
                this.childKredit = true
                this.childKredit2 = false
              }else if(responseCace.fidusia_dtl != '' && responseCace.fidusia_dtl != null){
                this.childKredit2 = true
                this.childKredit = false
              }else{
                this.childKredit = false
                this.childKredit2 = false
              }
            }else if(responseCace.klasifikasi == 3){
              if(responseCace.sertifikat_lain != '' && responseCace.sertifikat_lain != null){
                this.childSertifikat = true
              }else{
                this.childSertifikat = false
              }
              this.rPengurusanLain = 3
              this.isSertifikat = true
              this.isKredit = false
              this.isTransaksi = false
            }
            this.tgl_order_sementara = (new Date(Date.parse(responseCace.tgl_order) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.formItem.tgl_order_baru = this.converterFe(responseCace.tgl_order)
            this.tgl_akhir_pengurusan_sementara = (new Date(Date.parse(responseCace.tgl_akhir_pengurusan) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.formItem.tgl_akhir_pengurusan_baru = this.converterFe(responseCace.tgl_akhir_pengurusan)
            this.formItem.bank = this.cNasabah[0].bank
            this.formItem.cabang = this.cNasabah[0].cabang
            this.formItem.reminder = responseCace.reminder == '1' ? true : false
          })
        }
        // else{
        //   Swal.fire({
        //       title: "Gagal",
        //       text: "Gagal Memuat Data",
        //       icon: "warning",
        //       heightAuto: false,
        //   });
        // }
          resolve(responseCace);
          }, err =>{
          err
          })
      });
    },
    async onIntersect (entries, observer, isIntersecting) {
      entries
      observer
        if (isIntersecting) {
          if(this.cNasabah.length != this.ctNasabah || this.cNasabah.length==0){
            this.options_combo.page = this.options_combo.page + 1
            await this.getComboNasabah()
          }
        }
    },
    onPress () {
      clearTimeout(this.typingTimer)
        this.cNasabah = []
        this.options_combo.page = 1
        this.NoData = true
        this.textLoad = 'Sedang Memuat Data'

      this.typingTimer = 
      setTimeout(async () => {
        await this.getComboNasabah()
      }, this.doneTyping);
    },
    async focusAutocomplete(){
      this.cNasabah = []
      this.options_combo.page = 1
      this.NoData = true
      await this.getComboNasabah()
    },
    getComboNasabah(){
      return new Promise(resolve => {
      var mydata = {
          UID 		        : storagelocal.getLocalStorage('UID'),
          Token		        : storagelocal.getLocalStorage('token'),
          Trigger	        : 'R',
          options         : this.options_combo,
          id              : this.id_cNasabah,
          nama            : this.search,
      }
        Services.PostData(ApiService, "m_nasabah", mydata, async response=>{
          this.cNasabah = [...this.cNasabah, ...response.data]
          this.ctNasabah = response.total
          
          resolve(this.cNasabah);
          if(response.status != 1000){
            this.NoData = false
          }
          }, err =>{
          err
        })
      });
    },
    getComboPenjamin(){
      return new Promise(resolve => {
      var mydata = {
          UID 		        : storagelocal.getLocalStorage('UID'),
          Token		        : storagelocal.getLocalStorage('token'),
          Trigger	        : 'R',
          options         : this.options_combo_penjamin,
          id_nasabah      : this.idPenjamin,
      }
        Services.PostData(ApiService, "t_penjamin_nasabah", mydata, async response=>{
          this.itemPenjamin = [...this.itemPenjamin, ...response.data]
          
          resolve(this.itemPenjamin);
          }, err =>{
          err
        })
      });
    },
    getComboNotaris(){
      return new Promise(resolve => {
      var mydata = {
          UID 		        : storagelocal.getLocalStorage('UID'),
          Token		        : storagelocal.getLocalStorage('token'),
          Trigger	        : 'R',
          options         : this.options_combo_notaris,
      }
        Services.PostData(ApiService, "m_notaris", mydata, async response=>{
          this.itemNotaris = [...this.itemNotaris, ...response.data]
          
          resolve(this.itemNotaris);
          }, err =>{
          err
        })
      });
    },
    async sendToText(value){
      if(value != null){
        this.formItem.bank = value.bank
        this.formItem.cabang = value.cabang
        this.idPenjamin = value.id
        await this.getComboPenjamin()
      }
    },
    async showChildJenisPeng(value){
      if(value == 1){
        this.childPengurusan = true
        await this.getComboNotaris()
      }else{
        this.childPengurusan = false
      }
    },
    doTransaksi(){
      this.isTransaksi = true
      this.isKredit = false
      this.isSertifikat = false
      this.childTransaksi = false
      this.childSertifikat = false
      this.childKredit = false
      this.childKredit2 = false
    },
    doKredit(){
      this.isTransaksi = false
      this.isKredit = true
      this.isSertifikat = false
      this.childTransaksi = false
      this.childSertifikat = false
      this.childKredit = false
      this.childKredit2 = false
    },
    doSertifikat(){
      this.isTransaksi = false
      this.isKredit = false
      this.isSertifikat = true
      this.childTransaksi = false
      this.childSertifikat = false
      this.childKredit = false
      this.childKredit2 = false
    },
    showChildTrans(value){
      if(value == 7){
        this.childTransaksi = true
      }else{
        this.childTransaksi = false
      }
    },
    showChildSerti(value){
      if(value == 9){
        this.childSertifikat = true
      }else{
        this.childSertifikat = false
      }
    },
    showChildKredit(value){
      if(value == 6){
        this.childKredit = true
        this.childKredit2 = false
      }else if(value == 3){
        this.childKredit = false
        this.childKredit2 = true
      }else{
        this.childKredit = false
        this.childKredit2 = false
      }
    },

    //////form dokumen//////////////////////////////////////////
    getDataDokumen(){
      return new Promise(resolve => {
      var mydata = {
          UID 		        : storagelocal.getLocalStorage('UID'),
          Token		        : storagelocal.getLocalStorage('token'),
          Trigger	        : 'R',
          options         : this.options,
          id_nasabah      : this.id_nasabah == '' ? this.id_nasabah_create : this.id_nasabah
      }
        this.loadingTable = true
        Services.PostData(ApiService, "t_dokumen_nasabah", mydata, async response=>{
          var responseCace = response.data
          if(response.status == 1000){
              for (let j = 0; j < response.data.length; j++) {
                var row_data = response.data[j];
                row_data.buttonTable = false
                if(row_data.nama_file != '' && row_data.nama_file != null){
                  row_data.buttonTable = true
                }
                row_data.reminder_fe = row_data.reminder == '0' ? 'Tidak' : 'Ya'
                row_data.tgl_terima_fe = this.converterFe(row_data.tgl_terima)
                row_data.tgl_exp_fe = this.converterFe(row_data.tgl_exp)
                if(row_data.nama_file != '' && row_data.nama_file != null){
                  var conv = row_data.nama_file.split('/')
                  row_data.nama_file_conv = conv[1]
                }
              }
             
          this.dDokumen = response.data
          this.tdDokumen = response.total
            // this.editedItem = Object.assign({}, responseCace)
            // this.tgl_terima_sementara = (new Date(Date.parse(responseCace.tgl_terima) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            // this.editedItem.tgl_terima = this.converterFe(responseCace.tgl_terima)
            // this.tgl_exp_sementara = (new Date(Date.parse(responseCace.tgl_exp) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            // this.editedItem.tgl_exp = this.converterFe(responseCace.tgl_exp)
            // this.editedItem.reminder = responseCace.reminder == '1' ? true : false
          }
          this.loadingTable = false
          resolve(responseCace);
          }, err =>{
          err
        })
      });
    },
    removeInputPath: function (e) {
      e
      this.$refs.inputFile.reset();
    },
    onFileChange(e) {
      this.alert_file = false
      if(!e)
      return
    },
    doHapusFile(toggle){
      toggle()
      this.editedItem.source_upload = null
      this.editedItem.nama_file = ''
      this.is_source_remove = 1
    },
    async editItem (item) {
      this.alert_file = false
      this.is_source_remove = 0

      this.editedIndex = this.dDokumen.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.tgl_terima_sementara = (new Date(Date.parse(item.tgl_terima) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.editedItem.tgl_terima_baru = this.converterFe(item.tgl_terima)
      this.editedItem.tgl_terima = this.converterBe(item.tgl_terima)
      this.tgl_exp_sementara = (new Date(Date.parse(item.tgl_exp) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.editedItem.tgl_exp_baru = this.converterFe(item.tgl_exp)
      this.editedItem.tgl_exp = this.converterBe(item.tgl_exp)
      this.dialog = true

      if(this.editedItem.nama_file != null && this.editedItem.nama_file != ''){
        this.alert_file = true
      }
    },
    saveAllDok(saveObj){
      return new Promise(resolve => {
            Services.PostData(ApiService, "t_dokumen_nasabah", saveObj, response=>{
              resolve(response.data);
              if(response.status == 1000){
                Swal.fire({
                    title: "",
                    text: "Sukses Menambah Data",
                    icon: "success",
                    heightAuto: false,
                    timer: 1500
                });
              }else{
                Swal.fire({
                    title: "",
                    text: "Gagal Menambah Data",
                    icon: "info",
                    heightAuto: false,
                    timer: 1500
                });
              }
            }, err =>{
              err
            })
        });
    },
    async saveDok (data_save){
      var saveObj = {}
      var _this = this
      if( _this.$refs.formDok.validate()){
        if (this.editedIndex > -1) {
          saveObj = Object.assign({}, data_save)
          // saveObj.MenuUrl = this.$router.currentRoute.path
          saveObj.file_dokumen = null
          saveObj.id_nasabah = this.id_nasabah == '' ? this.id_nasabah_create : this.id_nasabah
          saveObj.UID = storagelocal.getLocalStorage('UID'),
          saveObj.Token = storagelocal.getLocalStorage('token'),
          saveObj.Trigger = 'U'
          saveObj.is_source_remove = this.is_source_remove
          saveObj.reminder = _this.editedItem.reminder == false ? 0 : 1
        } else {
          saveObj = Object.assign({}, data_save)
          saveObj.id_nasabah = this.id_nasabah == '' ? this.id_nasabah_create : this.id_nasabah
          // saveObj.MenuUrl = this.$router.currentRoute.path
          saveObj.UID = storagelocal.getLocalStorage('UID'),
          saveObj.Token = storagelocal.getLocalStorage('token'),
          saveObj.Trigger = 'C'
          saveObj.is_source_remove = this.is_source_remove
          saveObj.reminder = _this.editedItem.reminder == false ? 0 : 1
        }
        this.progressBar = true
          Promise.all([await this.saveAllDok(saveObj)])
            .then((results8)=>{
            results8
            this.progressBar = false
            this.close()
            this.getDataDokumen()
          })
      }
      // this.close()
    },
    close() {
      this.dialog = false
      this.$refs.formDok.resetValidation()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.alert_file = false
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async deleteItem (item){
      this.editedIndex = this.dDokumen.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
      // this.close()
    },
    async deleteItemConfirm (id) {
      var deleteObj = {
        //MenuUrl: this.$router.currentRoute.path
        }
      deleteObj.UID = storagelocal.getLocalStorage('UID'),
      deleteObj.Token = storagelocal.getLocalStorage('token'),
      deleteObj.Trigger = 'D'
      deleteObj.id_nasabah = this.id_nasabah == '' ? this.id_nasabah_create : this.id_nasabah
      deleteObj.id = id
    
      Promise.all([await this.del(deleteObj)])
        .then((resultsdel)=>{
        resultsdel
            
        this.closeDelete()
        this.getDataDokumen()
      })
    },
    del(deleteObj){
      return new Promise(resolve => {
          Services.PostData(ApiService, "t_dokumen_nasabah", deleteObj, response=>{
            resolve(response.data);
            if(response.status == 1000){
              Swal.fire({
                  title: "",
                  text: "Sukses Hapus Data",
                  icon: "success",
                  heightAuto: false,
                  timer: 1500
              });
            }else if(response.status == 2002){
              Swal.fire({
                  title: "",
                  text: "Data Tidak Dapat Di Hapus",
                  icon: "info",
                  heightAuto: false,
                  timer: 1500
              });
            }else{
              Swal.fire({
                  title: "",
                  text: "Gagal Hapus Data",
                  icon: "info",
                  heightAuto: false,
                  timer: 1500
              });
            }

          }, err =>{
            err
          })
      });
    },
    openWeb(link){
      // if(!link.match(/\.pdf/)){
        window.open(this.baseUrlUpload + '/' + link, '_blank').focus()
      // }
    },
    createDok(){
      var date_now = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.tgl_terima_sementara = date_now
      this.editedItem.tgl_terima = this.converterBe(this.tgl_terima_sementara)
      this.editedItem.tgl_terima_baru = this.converterFe(this.tgl_terima_sementara)
      this.tgl_exp_sementara = date_now
      this.editedItem.tgl_exp = this.converterBe(this.tgl_exp_sementara)
      this.editedItem.tgl_exp_baru = this.converterFe(this.tgl_exp_sementara)
      this.dialog = true
    },
    goTable(){
      this.$router.push({name: 'tablepengurusanlain'})
    },

    //////////form tanggal akad/////////////////////////////////////////////////
    getDataAkad(){
      return new Promise(resolve => {
      var mydata = {
          UID 		        : storagelocal.getLocalStorage('UID'),
          Token		        : storagelocal.getLocalStorage('token'),
          Trigger	        : 'R',
          options         : this.optionsAkad,
          id              : this.idData == '' ? this.id_create : this.idData
      }
        this.loadFormAkad = false
        Services.PostData(ApiService, "t_pengurusan_lain", mydata, async response=>{
          this.loadFormAkad = true
          if(response.status == 1000){
            var responseCace = response.data[0]
            var date_now = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.dAkad = []
            if(responseCace.tgl_akad ==  null){
              this.tgl_akad_sementara = date_now
              this.editedItemAkad.tgl_akad_baru = this.converterFe(this.tgl_akad_sementara)
              this.editedItemAkad.tgl_akad = this.converterBe(this.tgl_akad_sementara)
            }else{
              this.tgl_akad_sementara = (new Date(Date.parse(responseCace.tgl_akad) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
              this.editedItemAkad.tgl_akad_baru = this.converterFe(responseCace.tgl_akad)
              this.editedItemAkad.tgl_akad = this.converterBe(responseCace.tgl_akad)
            }
            if(responseCace.tgl_akte ==  null){
              this.tgl_akte_sementara = date_now
              this.editedItemAkad.tgl_akte_baru = this.converterFe(this.tgl_akte_sementara)
              this.editedItemAkad.tgl_akte = this.converterBe(this.tgl_akte_sementara)
            }else{
              this.tgl_akte_sementara = (new Date(Date.parse(responseCace.tgl_akte) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
              this.editedItemAkad.tgl_akte_baru = this.converterFe(responseCace.tgl_akte)
              this.editedItemAkad.tgl_akte = this.converterBe(responseCace.tgl_akte)
            }
            if(responseCace.tgl_akhir_akte ==  null){
              this.tgl_akhir_akte_sementara = date_now
              this.editedItemAkad.tgl_akhir_akte_baru = this.converterFe(this.tgl_akhir_akte_sementara)
              this.editedItemAkad.tgl_akhir_akte = this.converterBe(this.tgl_akhir_akte_sementara)
            }else{
              this.tgl_akhir_akte_sementara = (new Date(Date.parse(responseCace.tgl_akhir_akte) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
              this.editedItemAkad.tgl_akhir_akte_baru = this.converterFe(responseCace.tgl_akhir_akte)
              this.editedItemAkad.tgl_akhir_akte = this.converterBe(responseCace.tgl_akhir_akte)
            }
            // this.editedItemAkad = Object.assign({}, responseCace)
            this.editedItemAkad.id = responseCace.id
            this.editedItemAkad.lokasi_akad = responseCace.lokasi_akad
            this.editedItemAkad.no_akte = responseCace.no_akte

            this.jam_akad_sementara = responseCace.jam_akad
            this.editedItemAkad.jam_akad_baru = responseCace.jam_akad
            this.editedItemAkad.jam_akad = responseCace.jam_akad
          
          }
          resolve(responseCace);
          }, err =>{
          err
        })
      });
    },
    saveAllAkad(saveObj){
      return new Promise(resolve => {
            Services.PostData(ApiService, "t_pengurusan_lain", saveObj, response=>{
              resolve(response.data);
              if(response.status == 1000){
                Swal.fire({
                    title: "",
                    text: "Sukses Menambah Data",
                    icon: "success",
                    heightAuto: false,
                    timer: 1500
                });
              }else{
                Swal.fire({
                    title: "",
                    text: "Gagal Menambah Data",
                    icon: "info",
                    heightAuto: false,
                    timer: 1500
                });
              }
            }, err =>{
              err
            })
        });
    },
    saveAllAkad2(saveObj){
      return new Promise(resolve => {
            Services.PostData(ApiService, "m_nasabah", saveObj, response=>{
              resolve(response.data);
              if(response.status == 1000){
                // Swal.fire({
                //     title: "",
                //     text: "Sukses Menambah Data",
                //     icon: "success",
                //     heightAuto: false,
                //     timer: 1500
                // });
              }else{
                // Swal.fire({
                //     title: "",
                //     text: "Gagal Menambah Data",
                //     icon: "info",
                //     heightAuto: false,
                //     timer: 1500
                // });
              }
            }, err =>{
              err
            })
        });
    },
    async saveAkad (data_save){
      var saveObj = {}
      // var _this = this
      // if( _this.$refs.form.validate()){
        // if (this.editedIndexAkad > -1) {
          saveObj = Object.assign({}, data_save)
          // saveObj.MenuUrl = this.$router.currentRoute.path
          saveObj.id = this.idData == '' ? this.id_create : this.idData
          saveObj.UID = storagelocal.getLocalStorage('UID'),
          saveObj.Token = storagelocal.getLocalStorage('token'),
          saveObj.Trigger = 'U'
          saveObj.Route = 'update_lain'
        // } else {
        //   saveObj = Object.assign({}, data_save)
        //   saveObj.id_nasabah = this.idData
        //   // saveObj.MenuUrl = this.$router.currentRoute.path
        //   saveObj.UID = storagelocal.getLocalStorage('UID'),
        //   saveObj.Token = storagelocal.getLocalStorage('token'),
        //   saveObj.Trigger = 'C'
        // }
          Promise.all([await this.saveAllAkad(saveObj)])
            .then(async (results8)=>{
            results8
            saveObj.id = this.id_nasabah == '' ? this.id_nasabah_create : this.id_nasabah
            await this.saveAllAkad2(saveObj)
            this.getDataAkad()
          })
      // }
      // this.close()
    },
    
    ////////// form proses /////////////////////////////
    async editItemPro (item) {
      this.editedIndexPro = this.dProses.indexOf(item)
      this.editedItemPro = Object.assign({}, item)
      this.dialogPro = true
      this.id_cUser = item.id_user.split(",")
   
      this.tgl_mulai_sementara = (new Date(Date.parse(item.tgl_mulai) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.editedItemPro.tgl_mulai_baru = this.converterFe(item.tgl_mulai)
      this.editedItemPro.tgl_mulai = this.converterBe(item.tgl_mulai)
      this.tgl_akhir_sementara = (new Date(Date.parse(item.tgl_akhir) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.editedItemPro.tgl_akhir_baru = this.converterFe(item.tgl_akhir)
      this.editedItemPro.tgl_akhir = this.converterBe(item.tgl_akhir)
     
      Promise.all([
          await this.getComboUser()
      ])
      .then(async (results) => {
        results
        this.id_cUser = ''
        this.editedItemPro.id_user = []
        for (let i = 0; i < this.cUser.length; i++) {
            this.editedItemPro.id_user.push(this.cUser[i].id)
        }
      })
    },
    saveAllPro(saveObj){
      return new Promise(resolve => {
            Services.PostData(ApiService, "t_pengerjaan", saveObj, response=>{
              resolve(response.data);
              if(response.status == 1000){
                Swal.fire({
                    title: "",
                    text: "Sukses Menambah Data",
                    icon: "success",
                    heightAuto: false,
                    timer: 1500
                });
              }else{
                Swal.fire({
                    title: "",
                    text: "Gagal Menambah Data",
                    icon: "info",
                    heightAuto: false,
                    timer: 1500
                });
              }
            }, err =>{
              err
            })
        });
    },
    async savePro (data_save){
      var saveObj = {}
      var id_user_arr = []
      // var _this = this
      // if( _this.$refs.form.validate()){
        if (this.editedIndexPro > -1) {
          saveObj = Object.assign({}, data_save)
          // id_user_arr = this.id_cUser_2
          for (let i = 0; i <  data_save.id_user.length; i++) {
            if(data_save.id_user[i].id == undefined){
              id_user_arr.push(data_save.id_user[i])  
            }else{
              id_user_arr.push(data_save.id_user[i].id)
            }            
          }
          saveObj.id_user = id_user_arr
          // saveObj.MenuUrl = this.$router.currentRoute.path
          saveObj.id_source = this.idData == '' ? this.id_create : this.idData
          saveObj.id_pengurusan = 2
          saveObj.UID = storagelocal.getLocalStorage('UID'),
          saveObj.Token = storagelocal.getLocalStorage('token'),
          saveObj.Trigger = 'U'
        } else {
          for (let i = 0; i <  data_save.id_user.length; i++) {
            id_user_arr.push(data_save.id_user[i].id)
          }
          saveObj = Object.assign({}, data_save)
          saveObj.id_user = id_user_arr
          saveObj.id_source = this.idData == '' ? this.id_create : this.idData
          saveObj.id_pengurusan = 2
          // saveObj.MenuUrl = this.$router.currentRoute.path
          saveObj.UID = storagelocal.getLocalStorage('UID'),
          saveObj.Token = storagelocal.getLocalStorage('token'),
          saveObj.Trigger = 'C'
        }
        this.progressBarPro = true
          Promise.all([await this.saveAllPro(saveObj)])
            .then((results8)=>{
            results8
            this.progressBarPro = false
            this.closePro()
            this.getDataProses()
          })
      // }
      // this.close()
    },
    closePro() {
      this.dialogPro = false
      this.$refs.formPro.resetValidation()
      this.$nextTick(() => {
        this.editedItemPro = Object.assign({}, this.defaultItemPro)
        this.editedIndexPro = -1
      })
    },
    closeDeletePro () {
      this.dialogDeletePro = false
      this.$nextTick(() => {
        this.editedItemPro = Object.assign({}, this.defaultItemPro)
        this.editedIndexPro = -1
      })
    },
    async deleteItemPro (item){
      this.editedIndexPro = this.dProses.indexOf(item)
      this.editedItemPro = Object.assign({}, item)
      this.dialogDeletePro = true
      // this.close()
    },
    async deleteItemConfirmPro (id) {
      var deleteObj = {
        //MenuUrl: this.$router.currentRoute.path
        }
      deleteObj.UID = storagelocal.getLocalStorage('UID'),
      deleteObj.Token = storagelocal.getLocalStorage('token'),
      deleteObj.Trigger = 'D'
      deleteObj.id_source = this.idData == '' ? this.id_create : this.idData
      deleteObj.id = id
      deleteObj.id_pengurusan = 2
    
      Promise.all([await this.delPro(deleteObj)])
        .then((resultsdel)=>{
        resultsdel
            
        this.closeDeletePro()
        this.getDataProses()
      })
    },
    delPro(deleteObj){
      return new Promise(resolve => {
          Services.PostData(ApiService, "t_pengerjaan", deleteObj, response=>{
            resolve(response.data);
            if(response.status == 1000){
              Swal.fire({
                  title: "",
                  text: "Sukses Hapus Data",
                  icon: "success",
                  heightAuto: false,
                  timer: 1500
              });
            }else if(response.status == 2002){
              Swal.fire({
                  title: "",
                  text: "Data Tidak Dapat Di Hapus",
                  icon: "info",
                  heightAuto: false,
                  timer: 1500
              });
            }else{
              Swal.fire({
                  title: "",
                  text: "Gagal Hapus Data",
                  icon: "info",
                  heightAuto: false,
                  timer: 1500
              });
            }

          }, err =>{
            err
          })
      });
    },
    createPro(){
      this.dialogPro = true
      var date_now = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.tgl_mulai_sementara = date_now
      this.editedItemPro.tgl_mulai_baru = this.converter(this.tgl_mulai_sementara)
      this.editedItemPro.tgl_mulai = this.converterBe(this.tgl_mulai_sementara)
      this.tgl_akhir_sementara = date_now
      this.editedItemPro.tgl_akhir_baru = this.converter(this.tgl_akhir_sementara)
      this.editedItemPro.tgl_akhir = this.converterBe(this.tgl_akhir_sementara)
    },
    async onIntersectPro (entries, observer, isIntersecting) {
      entries
      observer
        if (isIntersecting) {
          if(this.cUser.length != this.ctUser || this.cUser.length==0){
            this.options_combo_user.page = this.options_combo_user.page + 1
            await this.getComboUser()
          }
        }
    },
    onPressPro () {
      clearTimeout(this.typingTimer)
        this.cUser = []
        this.options_combo_user.page = 1
        this.NoDataCUser = true
        this.textLoadCUser = 'Sedang Memuat Data'

      this.typingTimer = 
      setTimeout(async () => {
        await this.getComboUser()
      }, this.doneTyping);
    },
    async focusAutocompletePro(){
      this.cUser = []
      this.options_combo_user.page = 1
      this.NoDataCUser = true
      await this.getComboUser()
    },
    getDataProses(){
      this.dProses = []
      return new Promise(resolve => {
      var mydata = {
          UID 		        : storagelocal.getLocalStorage('UID'),
          Token		        : storagelocal.getLocalStorage('token'),
          Trigger	        : 'R',
          options         : this.optionsPro,
          id_source       : this.idData == '' ? this.id_create : this.idData,
          id_pengurusan   : 2
      }
      this.loadingTablePro = true
      Services.PostData(ApiService, "t_pengerjaan", mydata, async response=>{
        var responseCace = response.data
        this.dProses = response.data
        this.tdProses = response.total
        if(response.status == 1000){
            for (let j = 0; j < response.data.length; j++) {
              var row_data = response.data[j];
              row_data.tgl_mulai_fe = this.converterFe(row_data.tgl_mulai)
              row_data.tgl_akhir_fe = this.converterFe(row_data.tgl_akhir)
              if(row_data.status == '0'){
                  row_data.status_fe = 'Tidak Aktif'
              }else{
                  row_data.status_fe = 'Aktif'
              }
            }
        }
        // else{
        //   Swal.fire({
        //       title: "Gagal",
        //       text: "Gagal Memuat Data",
        //       icon: "warning",
        //       heightAuto: false,
        //   });
        // }
        this.loadingTablePro = false
          resolve(responseCace);
          }, err =>{
          err
          })
      });
    },
    getComboUser(){
      this.cUser = []
      return new Promise(resolve => {
      var mydata = {
          UID 		        : storagelocal.getLocalStorage('UID'),
          Token		        : storagelocal.getLocalStorage('token'),
          Trigger	        : 'R',
          Route           : 'without_level_admin',
          options         : this.options_combo_user,
          id_list         : this.id_cUser,
          nama            : this.searchComboUser,
      }
        Services.PostData(ApiService, "users", mydata, async response=>{
          this.cUser = [...this.cUser, ...response.data]
          this.ctUser = response.total
          
          resolve(this.cUser);
          if(response.status != 1000){
            this.NoDataCUser = false
          }
          }, err =>{
          err
        })
      });
    },
    stepOne(){
      this.showSaveForm = true
      this.loadForm = true
      this.textUnderButton = "Simpan"
    },
    stepTwo(){
      this.showSaveForm = true
      this.loadForm = false
      this.textUnderButton = "Selanjutnya"
    },
    stepThree(){
      this.showSaveForm = true
      this.loadForm = false
      this.textUnderButton = "Selanjutnya"
    },
    stepFour(){
      this.showSaveForm = false
    }
  }
};
</script>
